import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

const IndexPage = ({data}) => {
  console.log(data)
  let members = data.graphAPI.directors
  return (
  <Layout>
    <SEO title="Home" />
    <div className="container anchor">
      <div className="columns">
        <div className="column is-8 is-offset-1 inset">
          <h1 className="is-size-1" style={{ marginTop: 0}}>Board of Directors</h1>
          <p>The current board of directors</p>
          {members.map(member => (
            <div className="media">
              <figure className="media-left">
                <Img className="rounded accent" fixed={member.image.documentFile.childImageSharp.fixed} alt="" />
              </figure>
              <div className="media-content">
                <h3 style={{marginBottom: '0'}}>{member.Name}</h3>
                <p>{member.Role}</p>
                { member.content !== null ? (
                  <div dangerouslySetInnerHTML={ {__html: member.content} }></div>
                ) : null }
              </div>
              
            </div>
          ))}
        </div>
      </div>
    </div>
  </Layout>
)}

export default IndexPage

export const indexQuery = graphql`
  query {
    graphAPI {
      directors {
        Name
        Role
        content
        image {
          url
          updated_at
          created_at
          id
          documentFile {
            childImageSharp {
              fixed(width: 150, height: 150){
                ...GatsbyImageSharpFixed_tracedSVG
              }
            }
          }
        }
      }
    }
  }
`